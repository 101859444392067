import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Procesos/Procesos.js";
import PresentationHeader from "components/Headers/PresentationHeader2.js";

function Entes() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Mapa Procesos"/>
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <h3 className="title text-center" style={{color:"#2c2c2c"}} id="exampleModalLiveLabel">
              Mapa Procesos
            </h3>
            <Row>
              <Col md="6">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
                      Entes de control que vigilan a la entidad y mecanismos de supervisión
                    </h3>
                    <Col md="10">
                      <h4 className="text-justify">
                        La orientación, inspección, vigilancia y control a las Notarías lo ejerce la Superintendencia de Notariado
                        y Registro en virtud del artículo 4 del Decreto 2723 de 2014.
                        <br></br>
                        <br></br>
                        La Superintendencia de Notariado y Registro ejerce el control conforme a los informes estadisticos
                        que rinden mensualmente los Notarios, así como con ocasión de las visitas previstas en
                        los artículos 2.2.6.1.6.3.1 y siguientes del Decreto 1069 de 2015.
                        <br></br>
                        <br></br>
                        El control que ejerce la Superintendencia de Notariado y Registro sobre las Notarias es de
                        naturaleza administrativa y disciplinaria.
                        <br></br>
                        <br></br>
                        Sede Principal y Atención al Ciudadano
                        <br></br>
                        Calle 26 No. 13-49 Interior 201, Bogotá D.C. Colombia.
                        <br></br>
                        Código postal : # 110311 – 110311000
                        <br></br>
                        Nit: 899.999.007-0
                        <br></br>
                        Horario de Atención : 08:00 a.m a 05:00 p.m.
                        <br></br>
                        Telefono – Conmutador : 57+(1) 328 2121
                        <br></br>
                        Conmutador Atención al Ciudadano : 57+(601) 328 2121
                        <br></br>
                        Correo Institucional : correspondencia@supernotariado.gov.co
                        <br></br>
                        Sitio Web : https://www.supernotariado.gov.co/  
                      </h4>                
                    </Col>                      
                  </CardBody>                    
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Entes;